import { Page, Router } from '@happysanta/router';
import { Page as PageRoutes, RootView, WizardPanel, EpicPanel } from './routes';

export const router = new Router(
	{
		[PageRoutes.Main]: new Page(EpicPanel.Main, RootView.Main),
		[PageRoutes.Timetable]: new Page(EpicPanel.Timetable, RootView.Main),
		[PageRoutes.Settings]: new Page(EpicPanel.Settings, RootView.Main),
		[PageRoutes.SettingsGrade]: new Page(EpicPanel.SettingsGrade, RootView.Main),
		[PageRoutes.SettingsGroup]: new Page(EpicPanel.SettingsGroup, RootView.Main),

		[PageRoutes.Wizard]: new Page(WizardPanel.Grade, RootView.Wizard),
		[PageRoutes.WizardGroup]: new Page(WizardPanel.Group, RootView.Wizard),
	},
	{
		preventSameLocationChange: true,
	}
);

router.start();
