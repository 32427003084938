import { createEvent, restore } from 'effector';
import { persist } from 'effector-storage/local';

export const setFirstRun = createEvent<boolean>();
export const $firstRun = restore(setFirstRun, true);

persist({
	store: $firstRun,
	key: 'firstRunTag',
});
