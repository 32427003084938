import styled, { css } from 'styled-components';

const border = css`
	border-radius: 3px;
`;

export const Container = styled.div`
	${border};
	background-color: #f5f5f5;

	body[scheme='space_gray'] & {
		background-color: #2c2d2f;
	}
`;

export const Bar = styled.div`
	${border};
	height: 3px;
`;
