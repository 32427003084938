import styled from 'styled-components';

export const Container = styled.div`
	cursor: pointer;

	> .vkuiSelect {
		opacity: 1;
	}

	.vkuiSelect__title {
		color: var(--text_primary);
	}
`;
