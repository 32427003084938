import { setMenuToggle } from 'models';
import { BuildMetaProps } from './BuildMeta.interface';
import { VersionLine, Container } from './BuildMeta.styles';

export const BuildMeta = ({ build }: BuildMetaProps): JSX.Element => (
	<Container onClick={() => setMenuToggle(true)}>
		<VersionLine>
			v{build.version}
			{build.branch && `-${build.branch}`}
			{build.commit && ` (${build.commit})`}
		</VersionLine>

		{!build.isProduction && (
			<>
				<VersionLine>{new Date(build.timestamp).toLocaleString()}</VersionLine>

				<VersionLine>{build.builder}</VersionLine>
			</>
		)}
	</Container>
);
