import { Link, Placeholder } from '@vkontakte/vkui';
import { useStore } from 'effector-react';
import { ErrorPlaceholderProps } from './ErrorPlaceholder.interface';

export const ErrorPlaceholder = ({ store, reset }: ErrorPlaceholderProps): JSX.Element | null => {
	const error = useStore(store);

	return (
		error && (
			<Placeholder action={<Link onClick={reset}>Повторить попытку</Link>} stretched>
				Ошибка загрузки. Проверьте подключение к сети.
			</Placeholder>
		)
	);
};
