import React from 'react';
import ReactDOM from 'react-dom';
import { AdaptivityProvider, ConfigProvider } from '@vkontakte/vkui';
import { RouterContext } from '@happysanta/router';
import { router } from 'routing';
import { GlobalStyle } from 'styles';
import { App } from './App';

ReactDOM.render(
	<React.StrictMode>
		<ConfigProvider>
			<GlobalStyle />
			<AdaptivityProvider>
				<RouterContext.Provider value={router}>
					<App />
				</RouterContext.Provider>
			</AdaptivityProvider>
		</ConfigProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
