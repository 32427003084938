import styled from 'styled-components';

export const Container = styled.button`
	background: none;
	border: none;

	&:focus {
		outline: none;
	}
`;

export const VersionLine = styled.p`
	width: 100%;
	text-align: center;
	color: var(--text_secondary);
	font-size: 13px;
	margin: 0;
`;
