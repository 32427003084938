import styled from 'styled-components';
import { Logo as logo } from 'assets';

export const Container = styled.div`
	width: 100%;
`;

export const Caption = styled.div`
	font-size: 27px;
	margin: 4px 0 0;
	text-align: center;
	width: 100%;
`;

export const Logo = styled.img.attrs({
	src: logo,
	alt: 'App logotype',
})`
	width: 100px;
	margin: 0 auto;
	display: block;
	padding-bottom: 10px;
`;
