import { ThemeProvider } from 'styled-components';
import { Lesson } from 'lib';
import { AppColor } from 'styles';
import { Separator } from 'features/timetable/atoms';
import { LessonBlockProps } from './LessonBlock.interface';
import { Container, Content, Title, Asterisk, Description } from './LessonBlock.styles';

export const LessonBlock = ({ lesson, compact }: LessonBlockProps) => {
	const getDescription = (lesson: Lesson): string => {
		return [/^\d+$/.test(lesson.auditory) && lesson.auditory, lesson.teacher].filter((x) => !!x).join(', ');
	};

	return (
		<>
			<ThemeProvider theme={{ compact }}>
				<Container>
					<Separator color={lesson?.color ?? AppColor.Default} />
					{lesson ? (
						<Content>
							<Title>
								{lesson.subject}
								{lesson.replaced && <Asterisk>*</Asterisk>}
							</Title>
							<Description>{getDescription(lesson)}</Description>
						</Content>
					) : (
						<span>&mdash;</span>
					)}
				</Container>
			</ThemeProvider>
		</>
	);
};
