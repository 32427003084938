import { Group } from './types';

export const groups = [
	['8a', '8 А'],
	['8v', '8 В'],
	['9a', '9 А'],
	['9b', '9 Б'],
	['9v', '9 В'],
	['9g', '9 Г'],
	['9e', '9 Е'],
	['10a', '10 А'],
	['10b', '10 Б'],
	['10v', '10 В'],
	['10g', '10 Г'],
	['10d', '10 Д'],
	['10e', '10 Е'],
	['10z', '10 З'],
	['10k', '10 К'],
	['10l', '10 Л'],
	['10m', '10 М'],
	['10n', '10 Н'],
	['10s', '10 С'],
	['11a', '11 А'],
	['11b', '11 Б'],
	['11v', '11 В'],
	['11g', '11 Г'],
	['11d', '11 Д'],
	['11e', '11 Е'],
	['11z', '11 З'],
	['11k', '11 К'],
	['11l', '11 Л'],
	['11m', '11 М'],
	['11n', '11 Н'],
	['11s', '11 С'],
].map(([id, name]) => ({ id, name })) as Group[];

export const getGroupsByGrade = (grade: number | string): Group[] => {
	const prefix = grade.toString();
	return groups.filter((x) => x.name.startsWith(prefix));
};

export const getGroupNameById = (id: string): string | null => {
	return groups.find((x) => x.id === id)?.name ?? null;
};
