import styled from 'styled-components';

export const Description = styled.div`
	width: 90%;
	font-size: 13px;
	text-align: center;
	color: var(--text_secondary);
	margin: 1rem auto;

	> p {
		font-size: 14px;
		color: #893eb2;
		margin-top: 0;
	}
`;
