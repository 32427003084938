import { AppColor } from 'styles';
import { ProgressBarProps } from './ProgressBar.interface';
import { Bar, Container } from './ProgressBar.styles';

export const ProgressBar = ({ value, color }: ProgressBarProps): JSX.Element => (
	<Container>
		<Bar
			style={{
				width: `${value}%`,
				backgroundColor: color ?? AppColor.Default,
			}}
		/>
	</Container>
);
