import styled, { css } from 'styled-components';
import { AppColor } from 'styles';

const overflow = css`
	&,
	& > div {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
`;

export const Container = styled.div`
	height: 100%;
	width: 50%;
	display: flex;
	align-items: center;
`;

export const Content = styled.div`
	flex-grow: 1;
	${(props) => props.theme.compact && overflow};
`;

export const Title = styled.div`
	width: ${(props) => (props.theme.compact ? '95%' : 'initial')};
	font-weight: bold;
	font-size: 15px;
`;

export const Asterisk = styled.span`
	position: absolute;
	width: 0;
	color: ${AppColor.Red};
`;

export const Description = styled.span`
	${overflow};
	font-size: 12px;
`;
