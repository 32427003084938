import { ActionSheet, ActionSheetItem } from '@vkontakte/vkui';
import { TesterMenuProps } from './TesterMenu.interface';

export const TesterMenu = ({ onClose }: TesterMenuProps): JSX.Element => {
	const handleEraseLocalStorage = () => {
		try {
			localStorage.clear();
		} catch (error) {
			console.warn(error);
		}
	};

	return (
		<ActionSheet
			toggleRef={undefined}
			onClose={onClose}
			iosCloseItem={
				<ActionSheetItem autoclose mode='cancel'>
					Отменить
				</ActionSheetItem>
			}
		>
			<ActionSheetItem autoclose onClick={handleEraseLocalStorage}>
				Стереть локальное хранилище
			</ActionSheetItem>

			<ActionSheetItem
				autoclose
				mode='destructive'
				target='_blank'
				href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
			>
				Перезагрузить сервер
			</ActionSheetItem>
		</ActionSheet>
	);
};
