import { Snackbar } from '@vkontakte/vkui';
import { Icon24Done, Icon24Error } from '@vkontakte/icons';
import { Store } from 'effector';
import { useStore } from 'effector-react';
import { EventSnackbarProps } from './EventSnackbar.interface';
import { Container } from './EventSnackbar.styles';

export const EventSnackbar = ({ store, reset }: EventSnackbarProps): JSX.Element | null => {
	const event = useStore(store as Store<unknown>);
	const isError = event instanceof Error;

	return event ? (
		<Snackbar
			layout='vertical'
			onClose={reset}
			before={
				<Container size={24} $fault={isError}>
					{isError ? <Icon24Error width={14} height={14} /> : <Icon24Done width={14} height={14} />}
				</Container>
			}
		>
			{(event as Error).message ?? (event as Error).toString()}
		</Snackbar>
	) : null;
};
