import { LessonCard } from 'features/timetable/organisms';
import { NoLessons } from 'features/timetable/molecules';
import { TimetableProps } from './Timetable.interface';
import { Container } from './Timetable.styles';

export const Timetable = ({ lessons }: TimetableProps): JSX.Element =>
	lessons.length ? (
		<Container size='l'>
			{lessons.map((item, index) => (
				<LessonCard lesson={item} index={index} key={index} />
			))}
		</Container>
	) : (
		<NoLessons />
	);
