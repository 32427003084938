import bridge from '@vkontakte/vk-bridge';
import { useEffect } from 'react';
import { Panel, View } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { useStore } from 'effector-react';
import { Announcement, useCurrentDay } from 'lib';
import {
	$fetchStartupInfoError,
	$startupInfo,
	$userGroupId,
	fetchStartupInfoFx,
	resetFetchStartupInfoError,
	setActiveAnnouncement,
	setAnimationLock,
} from 'models';
import { AppModal, EpicPanel, Page, RootView } from 'routing';
import { AppHeader, CenteredSpinner, ErrorPlaceholder } from 'components';
import { NavIdProps } from 'types';
import { AnnouncementModalRoot } from 'features/home/pages/AnnouncementModalRoot';
import { Timetable } from 'features/timetable/templates';
import { ScrollAnnouncements, Snack } from 'features/home/organisms';
import { Section } from 'features/home/molecules';

export const HomePage = ({ id }: NavIdProps): JSX.Element => {
	const day = useCurrentDay();
	const location = useLocation();
	const router = useRouter();
	const group = useStore($userGroupId) ?? '';
	const startupInfo = useStore($startupInfo);
	const error = useStore($fetchStartupInfoError);
	const panel = location.getViewActivePanel(RootView.Main) || EpicPanel.Main;

	const handleAnnouncement = (announcement: Announcement) => {
		setActiveAnnouncement(announcement);
		router.pushModal(AppModal.Announcements);
	};

	useEffect(() => {
		if (error) {
			return;
		}

		if (!group) {
			router.replacePage(Page.Wizard);
			return;
		}

		fetchStartupInfoFx({ day: day.day, group }).then();
	}, [router, day, group, error]);

	useEffect(() => {
		setAnimationLock(false);
		bridge.send('VKWebAppInit').then();
	}, []);

	return (
		<View id={id} activePanel={panel} modal={<AnnouncementModalRoot />}>
			<Panel id={EpicPanel.Main}>
				<AppHeader />

				{startupInfo?.schedule ? (
					<>
						<Snack lessons={startupInfo.schedule} group={group} />

						<Section header='Объявления'>
							<ScrollAnnouncements
								announcements={startupInfo.announcements}
								setAnnouncement={handleAnnouncement}
							/>
						</Section>

						<Section header={`Расписание на ${day.label}`}>
							<Timetable lessons={startupInfo.schedule} />
						</Section>
					</>
				) : error ? (
					<ErrorPlaceholder store={$fetchStartupInfoError} reset={resetFetchStartupInfoError} />
				) : (
					<CenteredSpinner />
				)}
			</Panel>
		</View>
	);
};
