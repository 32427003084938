import { DayInfo, Weekday } from './types';

export const convertDurationToString = (delta: number): string => {
	return [(delta - (delta % 60)) / 60, delta % 60].map((x) => ('0' + x).slice(-2)).join(':');
};

export const getCurrentWeek = (date: Date = new Date()): Weekday => {
	const dates: Weekday = [];

	if (date.getDay() === 0) {
		date.setDate(date.getDate() + 1);
	}

	date.setDate(date.getDate() - date.getDay() + 1);

	for (let day = 0; day < 6; day++) {
		dates.push([
			date.toLocaleString(undefined, {
				weekday: 'short',
			}),
			date.getDate(),
		]);

		date.setDate(date.getDate() + 1);
	}

	return dates;
};

export const getDayInfo = (date: Date = new Date()): DayInfo => {
	const day = date.getDay();
	const hours = date.getHours();

	if (day === 0) {
		return {
			day: 1,
			label: 'понедельник',
		};
	}

	if (hours >= 16 || (hours === 15 && date.getMinutes() > 15)) {
		if (day === 6) {
			return {
				day: 1,
				label: 'понедельник',
			};
		}

		return {
			day: day + 1,
			label: 'завтра',
		};
	}

	return {
		day,
		label: 'сегодня',
	};
};
