import '@vkontakte/vkui/dist/vkui.css';
import bridge, { AnyReceiveMethodName, VKBridgeEvent } from '@vkontakte/vk-bridge';

bridge.subscribe(({ detail }: VKBridgeEvent<AnyReceiveMethodName>) => {
	if (detail.type !== 'VKWebAppUpdateConfig') {
		return;
	}

	const schemeAttr = document.createAttribute('scheme');
	schemeAttr.value = detail.data.scheme;
	document.body.attributes.setNamedItem(schemeAttr);
});

setTimeout(() => {
	if (window?.location?.hostname === 'localhost') {
		const schemeAttr = document.createAttribute('scheme');
		schemeAttr.value = 'space_gray';
		document.body.attributes.setNamedItem(schemeAttr);
	}
}, 1000);
