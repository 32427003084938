import { useStore } from 'effector-react';
import { Div, ModalPage, ModalRoot, Title } from '@vkontakte/vkui';
import { useLocation, useRouter } from '@happysanta/router';
import { $activeAnnouncement } from 'models';
import { AppModal } from 'routing';
import { ModalHeader } from 'components';
import { Content } from './AnnouncementModalRoot.style';

export const AnnouncementModalRoot = (): JSX.Element => {
	const location = useLocation();
	const router = useRouter();
	const activeAnnouncement = useStore($activeAnnouncement);

	const handleClose = () => router.popPage();

	return (
		<ModalRoot activeModal={location.getModalId()} onClose={handleClose}>
			<ModalPage
				id={AppModal.Announcements}
				onClose={handleClose}
				header={
					<ModalHeader onClose={handleClose} action='Закрыть'>
						Объявление
					</ModalHeader>
				}
				dynamicContentHeight={true}
			>
				<Div>
					<Title weight='medium' level='2'>
						{activeAnnouncement?.header}
					</Title>

					{activeAnnouncement && <Content dangerouslySetInnerHTML={{ __html: activeAnnouncement.content }} />}
				</Div>
			</ModalPage>
		</ModalRoot>
	);
};
