import { Platform, PlatformType } from '@vkontakte/vkui';
import styled from 'styled-components';

export const Root = styled.div`
	height: 25px;
`;

export const Container = styled.div<{ platform: PlatformType }>`
	position: fixed;
	left: 0;
	right: 0;
	z-index: 10;
	padding: 0 ${({ platform }) => (platform === Platform.ANDROID ? 16 : 12)}px;
	margin-top: -5px;
	background-color: var(--background_content);
`;

export const Content = styled.div`
	display: flex;
	height: 27px;
	font-size: 13px;
`;

export const Title = styled.div`
	display: flex;
	align-items: center;
`;

export const Timer = styled(Title)`
	margin-left: auto;
	color: var(--text_secondary);
`;
