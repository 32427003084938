import { PanelHeader, PanelHeaderBack } from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';
import { productBuildMeta } from 'lib';
import { AppHeaderProps } from './AppHeader.interface';

export const AppHeader = ({ children, withBack }: AppHeaderProps): JSX.Element => {
	const router = useRouter();

	return (
		<PanelHeader left={withBack && <PanelHeaderBack onClick={() => router.popPage()} />}>
			{children ?? productBuildMeta.appName}
		</PanelHeader>
	);
};
