import { Group, SimpleCell } from '@vkontakte/vkui';
import { Icon20EducationOutline, Icon28LinkOutline, Icon28LogoVkOutline } from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';
import { useStore } from 'effector-react';
import { getGroupNameById } from 'lib';
import { $userGroupId } from 'models';
import { Page } from 'routing';
import { PrimaryText } from 'components';

export const SettingsActions = (): JSX.Element => {
	const router = useRouter();
	const group = useStore($userGroupId);

	return (
		<>
			<Group>
				<SimpleCell
					expandable
					before={<Icon20EducationOutline width={28} height={28} />}
					after={group && <PrimaryText>{getGroupNameById(group)}</PrimaryText>}
					onClick={() => router.pushPage(Page.SettingsGrade)}
				>
					Класс
				</SimpleCell>

				<SimpleCell before={<Icon28LogoVkOutline />} target='_blank' href='https://vk.com/sescmate'>
					Группа ВКонтакте
				</SimpleCell>
			</Group>

			<Group>
				<SimpleCell
					before={<Icon28LinkOutline />}
					target='_blank'
					href='https://lyceum.urfu.ru/?utm_source=sescmate&utm_medium=app&utm_campaign=settings_link'
				>
					Сайт СУНЦ УрФУ
				</SimpleCell>
				<SimpleCell
					before={<Icon28LinkOutline />}
					target='_blank'
					href='https://lycreg.urfu.ru/?utm_source=sescmate&utm_medium=app&utm_campaign=settings_link'
				>
					Электронный журнал
				</SimpleCell>
			</Group>
		</>
	);
};
