import React, { useEffect } from 'react';
import { AppRoot, PanelHeader, Root, SplitCol, SplitLayout, useAdaptivity, ViewWidth } from '@vkontakte/vkui';
import { useStore } from 'effector-react';
import { useLocation, useRouter } from '@happysanta/router';
import { $lockAnimation, $userGroupId, setAnimationLock } from 'models';
import { EpicNavigation, Page, RootView } from 'routing';
import { WizardPage } from 'features/wizard/pages';
import './App.init';

export const App = (): JSX.Element => {
	const { viewWidth = 0 } = useAdaptivity();
	const location = useLocation();
	const router = useRouter();
	const view = location.getViewId();
	const lockAnimation = useStore($lockAnimation);
	const groupId = useStore($userGroupId);

	useEffect(() => {
		if (groupId === null) {
			setAnimationLock(true);
			router.replacePage(Page.Wizard);
		}
	}, [router, groupId]);

	return (
		<AppRoot>
			<SplitLayout header={<PanelHeader separator={false} />}>
				<SplitCol
					spaced={viewWidth > ViewWidth.MOBILE}
					animate={!lockAnimation && viewWidth <= ViewWidth.MOBILE}
				>
					<Root activeView={view}>
						<EpicNavigation id={RootView.Main} />
						<WizardPage id={RootView.Wizard} />
					</Root>
				</SplitCol>
			</SplitLayout>
		</AppRoot>
	);
};
