import { getCurrentWeek } from 'lib';
import { DayButton } from 'features/timetable/molecules';
import { DaySelectProps } from './DaySelect.interface';
import { Container } from './DaySelect.styles';

export const DaySelect = ({ value, onChange }: DaySelectProps): JSX.Element => (
	<Container>
		{getCurrentWeek().map(([short, day], index) => (
			<DayButton key={index} active={index === value} date={{ short, day }} onClick={() => onChange(index)} />
		))}
	</Container>
);
