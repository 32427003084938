import { TabbarItem } from '@vkontakte/vkui';
import { Icon28ArticleOutline, Icon28HomeOutline, Icon28MenuOutline } from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';
import { EpicPanel, Page } from 'routing';
import { EpicTabbarProps } from './EpicTabbar.interface';
import { ClickableTabbar } from './EpicTabbar.styles';

export const EpicTabbar = ({ tab }: EpicTabbarProps): JSX.Element => {
	const router = useRouter();

	return (
		<ClickableTabbar>
			<TabbarItem selected={tab === EpicPanel.Main} onClick={() => router.pushPage(Page.Main)}>
				<Icon28HomeOutline />
			</TabbarItem>

			<TabbarItem selected={tab === EpicPanel.Timetable} onClick={() => router.pushPage(Page.Timetable)}>
				<Icon28ArticleOutline />
			</TabbarItem>

			<TabbarItem selected={tab === EpicPanel.Settings} onClick={() => router.pushPage(Page.Settings)}>
				<Icon28MenuOutline />
			</TabbarItem>
		</ClickableTabbar>
	);
};
