import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
	#root {
		-webkit-touch-callout: none;
		-webkit-overflow-scrolling: touch;
		-webkit-user-select: none;
		user-select: none;
	}

	.PanelHeader__separator {
		display: none;
	}
	
	.vkuiHeader {
		-webkit-user-select: none !important;
		user-select: none !important;
	}
`;
