import { ANDROID, IOS, ModalPageHeader, PanelHeaderButton, usePlatform } from '@vkontakte/vkui';
import { Icon24Cancel } from '@vkontakte/icons';
import { ModalHeaderProps } from './ModalHeader.interface';

export const ModalHeader = ({ onClose, action, children }: ModalHeaderProps): JSX.Element => {
	const platform = usePlatform();

	return (
		<ModalPageHeader
			left={
				platform === ANDROID && (
					<PanelHeaderButton onClick={onClose}>
						<Icon24Cancel />
					</PanelHeaderButton>
				)
			}
			right={platform === IOS && <PanelHeaderButton onClick={onClose}>{action ?? 'Отмена'}</PanelHeaderButton>}
		>
			{children}
		</ModalPageHeader>
	);
};
