import { useRef } from 'react';
import { ModalPage, ModalRoot, SimpleCell } from '@vkontakte/vkui';
import { useStore } from 'effector-react';
import { useLocation, useRouter } from '@happysanta/router';
import { getGroupsByGrade } from 'lib';
import { $grade, setGrade, setGroupId } from 'models';
import { AppModal } from 'routing';
import { ModalHeader } from 'components';

export const GroupSelectModalRoot = (): JSX.Element => {
	const location = useLocation();
	const router = useRouter();
	const grade = useStore($grade);
	const closedRef = useRef<boolean>(false);

	const handleClose = () => {
		closedRef.current = true;
		router.popPage();
	};

	const handleGradeSelect = (grade: number) => {
		if (closedRef.current) {
			closedRef.current = false;
			return;
		}

		setGrade(grade);
		router.replaceModal(AppModal.Group);
	};

	const handleGroupSelect = (group: string) => {
		setGroupId(group);
		router.popPage();
	};

	return (
		<ModalRoot activeModal={location.getModalId()} onClose={handleClose}>
			<ModalPage
				id={AppModal.Grade}
				onClose={handleClose}
				header={<ModalHeader onClose={handleClose}>Выбор класса</ModalHeader>}
			>
				{[8, 9, 10, 11].map((grade, index) => (
					<SimpleCell key={index} onClick={() => handleGradeSelect(grade)}>
						{grade} класс
					</SimpleCell>
				))}
			</ModalPage>

			<ModalPage
				id={AppModal.Group}
				onClose={handleClose}
				header={<ModalHeader onClose={handleClose}>Выбор класса</ModalHeader>}
			>
				{grade &&
					getGroupsByGrade(grade).map(({ id, name }, index) => (
						<SimpleCell key={index} onClick={() => handleGroupSelect(id)}>
							{name}
						</SimpleCell>
					))}
			</ModalPage>
		</ModalRoot>
	);
};
