import { createEvent, restore } from 'effector';
import { persist } from 'effector-storage/local';

export const setUserGroupId = createEvent<string | null>();
export const $userGroupId = restore(setUserGroupId, null);

persist({
	store: $userGroupId,
	key: 'groupId',
});
