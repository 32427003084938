import { useState } from 'react';
import { EventMapper, Schedule, SnackState } from 'lib';
import { useInterval } from './useInterval';

export const useCurrentEvent = (...args: [Schedule[], string]): SnackState => {
	const mapper = new EventMapper(...args);
	const [event, setEvent] = useState<SnackState>(mapper.getCurrentEvent());

	useInterval(() => {
		setEvent(mapper.getCurrentEvent());
	}, 250);

	return event;
};
