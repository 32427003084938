import { useEffect } from 'react';
import { ScreenSpinner, View } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';
import { useStore } from 'effector-react';
import { useLocation } from '@happysanta/router';
import { useSwipeBack } from 'lib';
import { setAnimationLock, updateGroupFx } from 'models';
import { RootView, WizardPanel } from 'routing';
import { NavIdProps } from 'types';
import { GroupPanel, GradePanel } from 'features/wizard/oragnisms';

export const WizardPage = ({ id }: NavIdProps): JSX.Element => {
	const location = useLocation();
	const { history, onSwipeBack } = useSwipeBack(RootView.Wizard, WizardPanel.Grade);
	const panel = location.getViewActivePanel(RootView.Wizard) || WizardPanel.Grade;
	const loading = useStore(updateGroupFx.pending);

	useEffect(() => {
		setAnimationLock(false);
		bridge.send('VKWebAppInit').then();
	}, []);

	return (
		<View
			id={id}
			activePanel={panel}
			history={history}
			onSwipeBack={onSwipeBack}
			popout={loading && <ScreenSpinner />}
		>
			<GradePanel id={WizardPanel.Grade} />
			<GroupPanel id={WizardPanel.Group} />
		</View>
	);
};
