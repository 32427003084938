import { createApi, createEffect, createEvent, createStore, restore } from 'effector';
import { getStartupInfo } from './startup.api';
import { GetStartupInfoConfig, StartupInfo } from './startup.types';

export const setStartupInfo = createEvent<StartupInfo | null>();
export const $startupInfo = restore(setStartupInfo, null);

export const fetchStartupInfoFx = createEffect<GetStartupInfoConfig, StartupInfo>();

fetchStartupInfoFx.use(getStartupInfo);

fetchStartupInfoFx.use(({ day, group }) => getStartupInfo({ day, group }));

$startupInfo.on(fetchStartupInfoFx.doneData, (_, startupInfo) => startupInfo);

export const $fetchStartupInfoError = createStore<Error | null>(null);

$fetchStartupInfoError.on(fetchStartupInfoFx.failData, (_, error) => error);

export const { resetFetchStartupInfoError } = createApi($fetchStartupInfoError, {
	resetFetchStartupInfoError: (_) => null,
});
