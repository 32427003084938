type CfResponse<T> = {
	ts: number;
} & (
	| {
			ok: true;
			data: T;
	  }
	| {
			ok: false;
			error: string;
	  }
);

export const xFetch = async <T, C extends RequestInit>(url: string, config?: C) => {
	const response = await fetch(process.env.API_URL + url, {
		...config,
		headers: {
			...config?.headers,
			'Content-Type': 'application/json',
		},
	});

	const body: CfResponse<T> = await response.json();

	if (!body.ok) {
		throw new Error(body.error);
	}

	return body.data;
};
