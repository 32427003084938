import { Card } from '@vkontakte/vkui';
import { Time } from 'features/timetable/atoms';
import { LessonBlock } from 'features/timetable/molecules';
import { LessonCardProps } from './LessonCard.interface';
import { Container, Content, FlexContainer, SingleBlock } from './LessonCard.styles';

export const LessonCard = ({ lesson, index }: LessonCardProps): JSX.Element => (
	<Card>
		<Container>
			<Time lessonIndex={index} />
			<Content>
				{Array.isArray(lesson) ? (
					<FlexContainer>
						<LessonBlock compact lesson={lesson[0]} />
						<LessonBlock compact lesson={lesson[1]} />
					</FlexContainer>
				) : (
					<SingleBlock lesson={lesson} />
				)}
			</Content>
		</Container>
	</Card>
);
