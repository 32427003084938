import { getIntervalByIndex } from 'lib';
import { TimeProps } from './Time.interface';
import { Container } from './Time.styles';

export const Time = ({ lessonIndex }: TimeProps): JSX.Element => {
	const [start, end] = getIntervalByIndex(lessonIndex);

	return (
		<Container>
			<div>
				<div>{start}</div>
				<div>{end}</div>
			</div>
		</Container>
	);
};
