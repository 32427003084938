import { productBuildMeta } from 'lib';
import { SnailLogoProps } from './SnailLogo.interface';
import { Container, Caption, Logo } from './SnailLogo.styles';

export const SnailLogo = ({ caption }: SnailLogoProps): JSX.Element => (
	<Container>
		<Logo />

		<Caption>{caption ?? productBuildMeta.appName}</Caption>
	</Container>
);
