import { Header } from '@vkontakte/vkui';
import { SectionProps } from './Section.interface';
import { CustomGroup } from './Section.styles';

export const Section = ({ children, header }: SectionProps): JSX.Element => {
	return (
		<CustomGroup separator='hide' header={<Header mode='secondary'>{header}</Header>}>
			{children}
		</CustomGroup>
	);
};
