import { Card } from '@vkontakte/vkui';
import styled from 'styled-components';

export const Container = styled(Card)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	width: 297px !important;
	height: 61px;
	padding: 0 16px;
`;

export const Header = styled.div`
	font-size: 15px;
	font-weight: bold;
`;

export const Caption = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
`;
