import { attach, createApi, createEffect, createEvent, createStore, restore } from 'effector';
import { getDayInfo, Schedule } from 'lib';
import { $userGroupId } from 'models/userGroupId';
import { getSchedule } from './schedule.api';
import { GetScheduleConfig } from './schedule.types';

export const setGroupId = createEvent<string | null>();
export const $groupId = $userGroupId.map((state) => state);

$groupId.on(setGroupId, (_, groupId) => groupId);

export const setDay = createEvent<number>();
export const $day = restore(setDay, getDayInfo().day - 1);

export const $schedule = createStore<Array<Schedule[]> | null>(null);

const fetchSchedule = createEffect<GetScheduleConfig, Array<Schedule[]>>();

fetchSchedule.use(getSchedule);

$schedule.reset($groupId);

$schedule.on(fetchSchedule.doneData, (_, schedule) => schedule);

export const fetchScheduleFx = attach({
	effect: fetchSchedule,
	source: $groupId,
	mapParams: (_, groupId) => ({ groupId: groupId ?? '' }), // dirty
});

export const $fetchScheduleError = createStore<Error | null>(null);

$fetchScheduleError.on(fetchScheduleFx.failData, (_, error) => error);

export const { resetFetchScheduleError } = createApi($fetchScheduleError, {
	resetFetchScheduleError: (_) => null,
});
