import { useEffect } from 'react';
import { Panel } from '@vkontakte/vkui';
import { $updateGroupDone, resetUpdateGroupDone } from 'models';
import { AppHeader, EventSnackbar } from 'components';
import { productBuildMeta } from 'lib';
import { NavIdProps } from 'types';
import { BuildMeta, SettingsActions, SnailLogo } from 'features/settings/molecules';
import { AboutContainer } from './AboutPanel.styles';

export const AboutPanel = ({ id }: NavIdProps): JSX.Element => {
	useEffect(
		() => () => {
			resetUpdateGroupDone();
		},
		[]
	);

	return (
		<Panel id={id}>
			<AppHeader />

			<AboutContainer>
				<SnailLogo />
				<BuildMeta build={productBuildMeta} />
			</AboutContainer>

			<SettingsActions />

			<EventSnackbar store={$updateGroupDone} reset={resetUpdateGroupDone} />
		</Panel>
	);
};
