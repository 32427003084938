import styled from 'styled-components';
import { LessonBlock } from 'features/timetable/molecules';

export const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	align-items: flex-start;
	height: 61px;
`;

export const Content = styled.div`
	flex-grow: 1;
	height: 100%;
	width: 0;
	margin-right: 10px;
`;

export const FlexContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 100%;
`;

export const SingleBlock = styled(LessonBlock)`
	width: calc(100% - 10px);
`;
