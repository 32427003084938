import { DayButtonProps } from './DayButton.interface';
import { Option, Short, Pil, PilContainer } from './DayButton.styles';

export const DayButton = ({ active, date, onClick }: DayButtonProps): JSX.Element => (
	<Option active={active} onClick={onClick}>
		<div>{date.day}</div>
		<Short>{date.short}</Short>
		<PilContainer>
			<Pil active={active} />
		</PilContainer>
	</Option>
);
