import { SeparatorProps } from './Separator.interface';
import { Container, SeparatorDiv } from './Separator.styles';

export const Separator = ({ color }: SeparatorProps): JSX.Element => {
	return (
		<Container>
			<SeparatorDiv color={color} />
		</Container>
	);
};
