import { Placeholder } from '@vkontakte/vkui';
import { Shrug } from './NoLessons.styles';

export const NoLessons = (): JSX.Element => {
	return (
		<Placeholder>
			<Shrug>¯\_(ツ)_/¯</Shrug>
			<div>На этот день уроки не запланированы!</div>
		</Placeholder>
	);
};
