import { useRef } from 'react';
import bridge, { RequestPropsMap } from '@vkontakte/vk-bridge';
import { useLocation, useRouter } from '@happysanta/router';

export const useSwipeBack = (
	viewId: string,
	mainPanel: string
): {
	onSwipeBack: () => void;
	history: string[];
} => {
	const location = useLocation();
	const router = useRouter();
	const state = useRef<boolean>(false);
	const history = location.getViewHistory(viewId);

	const isCurrent = history.slice(-1)[0] === mainPanel;

	if (isCurrent !== state.current) {
		state.current = isCurrent;
		bridge.send(`VKWebApp${isCurrent ? 'Disable' : 'Enable'}SwipeBack` as keyof RequestPropsMap).then();
	}

	return {
		onSwipeBack: () => router.popPage(),
		history: state.current ? [mainPanel] : history,
	};
};
