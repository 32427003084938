export const generateCaption = (content: string, cut: boolean) => {
	try {
		return Array.from(new DOMParser().parseFromString(content, 'text/html').body.children)
			.slice(cut ? 1 : 0)
			.map((x) => (x as HTMLElement).innerText)
			.join(' • ');
	} catch (e) {
		console.warn(e);
		return '—';
	}
};
