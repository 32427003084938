import { useLocation } from '@happysanta/router';
import { Epic } from '@vkontakte/vkui';
import React from 'react';

import { EpicPanel, RootView } from 'routing';
import { NavIdProps } from 'types';
import { HomePage } from '../features/home/pages';
import { SettingsPage } from '../features/settings/pages';
import { SchedulePage } from '../features/timetable/pages';
import { EpicTabbar, EpicTabbarProps } from './EpicTabbar';

export const EpicNavigation = ({ id }: NavIdProps): JSX.Element => {
	const location = useLocation();
	const story = (location.getViewActivePanel(RootView.Main) || EpicPanel.Main).split('--')[0];

	return (
		<Epic id={id} tabbar={<EpicTabbar tab={story as EpicTabbarProps['tab']} />} activeStory={story}>
			<HomePage id={EpicPanel.Main} />
			<SchedulePage id={EpicPanel.Timetable} />
			<SettingsPage id={EpicPanel.Settings} />
		</Epic>
	);
};
