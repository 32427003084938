import styled from 'styled-components';

export const AboutContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 31vh;
	min-height: 210px;
	justify-content: center;
	align-items: center;
`;
