import { Avatar } from '@vkontakte/vkui';
import styled from 'styled-components';

export const Container = styled(Avatar)<{ $fault: boolean }>`
	background: linear-gradient(
		135deg,
		${(props) => (props.$fault ? 'var(--dynamic_red), var(--destructive)' : 'var(--dynamic_blue), #3f8ae0')}
	);
	border-radius: 32px;

	svg {
		color: #fff;
	}
`;
