export enum Page {
	Main = '/',
	Timetable = '/timetable',
	Settings = '/settings',
	SettingsGrade = '/settings/grade',
	SettingsGroup = '/settings/group',
	Wizard = '/wizard',
	WizardGroup = '/wizard/group',
}

export enum RootView {
	Main = 'panel-main',
	Wizard = 'panel-wizard',
}

export enum WizardPanel {
	Grade = 'wizard',
	Group = 'wizard-group',
}

export enum EpicPanel {
	Main = 'epic-main',
	Timetable = 'epic-timetable',
	Settings = 'epic-settings',
	SettingsGrade = 'epic-settings--grade',
	SettingsGroup = 'epic-settings--group',
}

export enum AppModal {
	Announcements = 'modal-announcements',
	Grade = 'modal-grade',
	Group = 'modal-group',
}
