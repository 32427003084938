import { CardScroll } from '@vkontakte/vkui';
import { useStore } from 'effector-react';
import { $firstRun, setFirstRun } from 'models';
import { AnnouncementCard } from 'features/home/molecules';
import { ScrollAnnouncementsProps } from './AnnouncementsScroll.interface';

export const ScrollAnnouncements = ({ announcements, setAnnouncement }: ScrollAnnouncementsProps): JSX.Element => {
	const firstRun = useStore($firstRun);

	return (
		<CardScroll size='m'>
			{announcements.map((announcement, index) => (
				<AnnouncementCard
					key={index}
					announcement={announcement}
					showTooltip={firstRun && index === 0}
					onTooltipClose={() => setFirstRun(false)}
					onClick={() => setAnnouncement?.(announcement)}
				/>
			))}
		</CardScroll>
	);
};
