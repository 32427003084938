import { Tooltip } from '@vkontakte/vkui';
import { generateCaption } from 'lib';
import { AnnouncementCardProps } from './AnnouncementCard.interface';
import { Container, Header, Caption } from './AnnouncementCard.styles';

export const AnnouncementCard = (props: AnnouncementCardProps): JSX.Element => (
	<Tooltip
		text='Нажмите на карточку, чтобы узнать больше.'
		isShown={props.showTooltip}
		onClose={props.onTooltipClose}
	>
		<Container onClick={props.onClick}>
			<Header>{props.announcement.header}</Header>

			<Caption>{generateCaption(props.announcement.content, !props.announcement.trustedOrigin)}</Caption>
		</Container>
	</Tooltip>
);
