import { FormItem, FormLayout } from '@vkontakte/vkui';
import { useStore } from 'effector-react';
import { useRouter } from '@happysanta/router';
import { getGroupNameById } from 'lib';
import { $day, $groupId, setDay } from 'models';
import { AppModal } from 'routing';
import { DaySelect } from 'features/timetable/molecules';
import { FakeSelect } from 'features/timetable/atoms';

export const ScheduleControls = (): JSX.Element => {
	const router = useRouter();
	const day = useStore($day);
	const groupId = useStore($groupId);

	return (
		<FormLayout>
			<FormItem>
				<FakeSelect
					title={getGroupNameById(groupId ?? '') ?? ''}
					onClick={() => router.pushModal(AppModal.Grade)}
				/>
			</FormItem>

			<FormItem>
				<DaySelect value={day} onChange={setDay} />
			</FormItem>
		</FormLayout>
	);
};
