import { NativeSelect } from '@vkontakte/vkui';
import { MutableRefObject, useEffect, useRef } from 'react';
import { FakeSelectProps } from './FakeSelect.interface';
import { Container } from './FakeSelect.style';

export const FakeSelect = ({ title, onClick }: FakeSelectProps): JSX.Element => {
	const ref = useRef<HTMLLabelElement>() as MutableRefObject<HTMLLabelElement>;

	useEffect(() => {
		const titleEl = ref.current.querySelector('.Select__title');

		if (titleEl) {
			titleEl.innerHTML = title;
		}
	}, [title, ref]);

	return (
		<Container onClick={onClick}>
			<NativeSelect disabled placeholder={title} getRootRef={ref} />
		</Container>
	);
};
