import { useEffect } from 'react';
import { Panel, View } from '@vkontakte/vkui';
import { useStore } from 'effector-react';
import { useLocation } from '@happysanta/router';
import { $day, $fetchScheduleError, $groupId, $schedule, fetchScheduleFx, resetFetchScheduleError } from 'models';
import { EpicPanel, RootView } from 'routing';
import { AppHeader, CenteredSpinner, ErrorPlaceholder } from 'components';
import { NavIdProps } from 'types';
import { GroupSelectModalRoot } from 'features/timetable/pages';
import { Timetable } from 'features/timetable/templates';
import { ScheduleControls } from 'features/timetable/organisms';

export const SchedulePage = ({ id }: NavIdProps): JSX.Element => {
	const location = useLocation();
	const groupId = useStore($groupId);
	const day = useStore($day);
	const schedule = useStore($schedule);
	const error = useStore($fetchScheduleError);
	const panel = location.getViewActivePanel(RootView.Main) || EpicPanel.Timetable;

	useEffect(() => {
		if (error) {
			return;
		}

		fetchScheduleFx({ groupId }).then();
	}, [groupId, error]);

	return (
		<View id={id} activePanel={panel} modal={<GroupSelectModalRoot />}>
			<Panel id={EpicPanel.Timetable}>
				<AppHeader />

				<ScheduleControls />

				{schedule ? (
					<Timetable lessons={schedule[day]} />
				) : error ? (
					<ErrorPlaceholder store={$fetchScheduleError} reset={resetFetchScheduleError} />
				) : (
					<CenteredSpinner />
				)}
			</Panel>
		</View>
	);
};
