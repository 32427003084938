import { convertDurationToString } from './helpers';
import { Lesson, Schedule, SnackState } from './types';

export const getIntervalByIndex = (index: number): [string, string] => [
	convertDurationToString(EventMapper.timeMap[index]),
	convertDurationToString(EventMapper.timeMap[index] + 40),
];

export class EventMapper {
	static timeMap = [540, 590, 645, 700, 755, 815, 875];
	lessons: Schedule[];
	group: string;
	lessonsMap = [14400, 17400, 20700, 24000, 27300, 30900, 34500];
	breakDuration = [600, 900, 900, 900, 1200, 1200];

	constructor(lessons: Schedule[], group: string) {
		this.lessons = lessons;
		this.group = group;
	}

	getBreakfastNumber = (): number => {
		return 1;
	};

	getLunchNumber = (): number => this.getBreakfastNumber() + 3;

	getDinnerNumber = (): number => 7;

	getEatingTitle = (index: number): string => ['Завтрак', 'Обед', 'Полдник'][index];

	getLessonTitleByIndex = (index: number, n: number): string => {
		return (this.lessons[index] as Lesson[])[n]?.subject || '—';
	};

	getLessonTitle = (index: number): string | null => {
		if (!this.lessons || index > 6 || index < 0 || this.lessons.length < index + 1) {
			return null;
		}

		const cl = this.lessons[index];

		if (cl === null) {
			return null;
		}

		const title = Array.isArray(cl)
			? cl[0]?.subject === cl[1]?.subject
				? this.getLessonTitleByIndex(index, 0)
				: `${this.getLessonTitleByIndex(index, 0)} / ${this.getLessonTitleByIndex(index, 1)}`
			: cl.subject;

		return `Урок №${index + 1}: ${title}`;
	};

	getLessonColor = (index: number): string => {
		if (Array.isArray(this.lessons[index])) {
			const lesson = this.lessons[index] as Lesson[];

			return lesson[0]?.color || lesson[1]?.color;
		}

		return (this.lessons[index] as Lesson).color;
	};

	getNowBreak = (delta: number): SnackState => {
		for (let i = 0; i < this.lessonsMap.length; i++) {
			const passed = delta - this.lessonsMap[i];

			if (this.lessonsMap[i] + 2400 <= delta && delta < this.lessonsMap[i + 1]) {
				return {
					type: 'break',
					index: i + 1,
					title: 'Перемена',
					percent: ((passed - 2400) * 100) / this.breakDuration[i],
					left: convertDurationToString(this.breakDuration[i] + 2400 - passed),
				};
			}

			if (this.lessonsMap[i] <= delta && delta < this.lessonsMap[i] + 2400) {
				const title = this.getLessonTitle(i);

				if (title === null) {
					return {
						type: 'off',
					};
				}

				return {
					type: 'lesson',
					title,
					index: i + 1,
					color: this.getLessonColor(i),
					percent: passed / 24,
					left: convertDurationToString(2400 - passed),
				};
			}
		}

		const last = this.lessonsMap.slice(-1)[0];

		if (delta < last + 5100 && delta > this.lessonsMap[0]) {
			return {
				type: 'break',
				index: 7,
				title: null,
				percent: ((delta - last - 2400) * 100) / 2700,
				left: convertDurationToString(5100 - delta + last),
			};
		}

		return {
			type: 'off',
		};
	};

	getEatingNumbers = () => [this.getBreakfastNumber(), this.getLunchNumber(), this.getDinnerNumber()];

	getCurrentEvent(ts?: number | Date): SnackState {
		const date = ~~((typeof ts === 'number' ? ts : ts instanceof Date ? ts.getTime() : Date.now()) / 1000);
		const init = new Date(date * 1000);

		if (init.getDay() === 0) {
			return {
				type: 'off',
			};
		}

		const delta = date % 86400;
		const currentEvent = this.getNowBreak(delta);
		const eatingSchedule = this.getEatingNumbers();

		if (currentEvent.type === 'break' && currentEvent.index) {
			const index = eatingSchedule.indexOf(currentEvent.index);

			if (index > -1) {
				Object.assign(currentEvent, {
					type: 'eating',
					title: this.getEatingTitle(index),
				});
			}
		}

		if (currentEvent.type === 'lesson' && !currentEvent.title) {
			return {
				type: 'off',
			};
		}

		return currentEvent;
	}
}
