import { useState } from 'react';
import { DayInfo, getDayInfo } from 'lib';
import { useInterval } from './useInterval';

export const useCurrentDay = (): DayInfo => {
	const [dayInfo, setDayInfo] = useState<DayInfo>(getDayInfo());

	useInterval(() => {
		const next = getDayInfo(new Date());

		if (next.day !== dayInfo.day) {
			setDayInfo(next);
		}
	}, 10000);

	return dayInfo;
};
