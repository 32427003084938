import { Panel, SimpleCell } from '@vkontakte/vkui';
import { useRouter } from '@happysanta/router';
import { setGrade } from 'models';
import { Page } from 'routing';
import { AppHeader, SeparatedList } from 'components';
import { NavIdProps } from 'types';

export const GradePanel = ({ id }: NavIdProps): JSX.Element => {
	const router = useRouter();

	const handleGradeSelect = (grade: number) => {
		setGrade(grade);
		router.pushPage(Page.SettingsGroup);
	};

	return (
		<Panel id={id}>
			<AppHeader withBack>Изменить класс</AppHeader>

			<SeparatedList>
				{[8, 9, 10, 11].map((grade, index) => (
					<SimpleCell key={index} onClick={() => handleGradeSelect(grade)}>
						{grade} класс
					</SimpleCell>
				))}
			</SeparatedList>
		</Panel>
	);
};
