import { usePlatform } from '@vkontakte/vkui';
import { useCurrentEvent } from 'lib';
import { ProgressBar } from 'features/home/molecules';
import { SnackProps } from './Snack.interface';
import { Root, Container, Content, Title, Timer } from './Snack.styles';

export const Snack = ({ lessons, group }: SnackProps): JSX.Element | null => {
	const platform = usePlatform();
	const event = useCurrentEvent(lessons, group);

	return event.type !== 'off' ? (
		<Root>
			<Container platform={platform}>
				<Content>
					<Title>{event.title}</Title>

					<Timer>{event.left}</Timer>
				</Content>

				<ProgressBar value={event.percent} color={event.color} />
			</Container>
		</Root>
	) : null;
};
