import { createApi, createEffect, createStore } from 'effector';
import { getGroupNameById } from 'lib';
import { putUpdateGroup } from './updateGroup.api';
import { UpdateGroupConfig } from './updateGroup.types';

export const updateGroupFx = createEffect<UpdateGroupConfig, UpdateGroupConfig>(putUpdateGroup);

export const $updateGroupError = createStore<Error | null>(null);

$updateGroupError.on(updateGroupFx.failData, (_, error) => error);

export const { resetUpdateGroupError } = createApi($updateGroupError, {
	resetUpdateGroupError: (_) => null,
});

export const $updateGroupDone = createStore<string | null>(null);

$updateGroupDone.on(
	updateGroupFx.done,
	(_, response) => `Поздравляем с успешным переводом в ${getGroupNameById(response.result.group)}!`
);

export const { resetUpdateGroupDone } = createApi($updateGroupDone, {
	resetUpdateGroupDone: (_) => null,
});
