import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 8px -1px 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`;

export const SeparatorDiv = styled.div`
	width: 3px;
	height: 39px;
	border-radius: 99px;
	background: ${(props) => props.color};
`;
