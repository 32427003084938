import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 100%;
	margin: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	font-size: 15px;
`;
