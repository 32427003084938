import styled from 'styled-components';
import { ActiveProps } from './DayButton.interface';

export const Option = styled.div<ActiveProps>`
	width: 16.6667%;
	text-align: center;
	color: var(--text_ ${(props) => (props.active ? 'primary' : 'secondary')});
	cursor: pointer;
`;

export const Short = styled.div`
	font-size: 16px;
	font-weight: 500;
	text-transform: uppercase;
`;

export const PilContainer = styled.div`
	display: flex;
	margin-top: 8px;
	height: 2px;
	width: 100%;
	justify-content: center;
`;

export const Pil = styled.div<ActiveProps>`
	background: var(--accent);
	width: ${(props) => (props.active ? '30px' : 0)};
	opacity: ${(props) => (props.active ? 1 : 0)};
	height: 2px;
	border-radius: 2px;
	transition: width 0.2s ease 0s, color 0.2s ease 0s ${(props) => (props.active ? '' : ', opacity 0s linear .22s')};
`;
