import { ScreenSpinner, View } from '@vkontakte/vkui';
import { useStore } from 'effector-react';
import { useLocation } from '@happysanta/router';
import { useSwipeBack } from 'lib';
import { $menuToggle, setMenuToggle, updateGroupFx } from 'models';
import { EpicPanel, RootView } from 'routing';
import { NavIdProps } from 'types';
import { AboutPanel, GradePanel, GroupPanel, TesterMenu } from 'features/settings/organisms';

export const SettingsPage = ({ id }: NavIdProps): JSX.Element => {
	const location = useLocation();
	const { history, onSwipeBack } = useSwipeBack(RootView.Main, EpicPanel.Settings);
	const panel = location.getViewActivePanel(RootView.Main) || EpicPanel.Settings;
	const isUpdating = useStore(updateGroupFx.pending);
	const menu = useStore($menuToggle);

	return (
		<View
			id={id}
			activePanel={panel}
			history={history}
			onSwipeBack={onSwipeBack}
			popout={isUpdating ? <ScreenSpinner /> : menu ? <TesterMenu onClose={() => setMenuToggle(false)} /> : null}
		>
			<AboutPanel id={EpicPanel.Settings} />
			<GradePanel id={EpicPanel.SettingsGrade} />
			<GroupPanel id={EpicPanel.SettingsGroup} />
		</View>
	);
};
